import * as React from "react"

const DatenschutzPage = () => (
  <>
    <section
      id="datenschutz"
      className="section"
      /* className="section next-section-gradient-to-grey" */
      data-scroll-section
    >
      <div className="container is-max-widescreen">
        <h2 className="title">Datenschutz</h2>
        <div className="columns">
          <div className="column">
            <div className="content mb-6">
              <h3 className="title is-5">Headline</h3>
              <div className="table-container">
                <p>Lorem Ipsum</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </>
)

export default DatenschutzPage
